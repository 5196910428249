import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
// import { FaBed, FaBath, FaWarehouse } from 'react-icons/fa';
import "../../css/plans.css";
import { Row, Col } from 'react-bootstrap';
import parade2018 from "../../images/parade-2018.jpg";
import parade2019 from "../../images/parade-2019.jpg";
import parade2022_1 from "../../images/parade-2022-1.jpg";
import parade2022_2 from "../../images/parade-2022-2.jpg";

class AboutPage extends React.Component {
  render(){

    return (
      <Layout>
        <SEO title="Parade Homes" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4">Our Parade Homes</h1>
            </div>
          </div>
          <div className="row mt-4 mb-5">
              <Col>
                
                <Link to="/parade/2022-1">

                  <img src={parade2022_1} className="img-fluid" alt="Awards"/>

                  <Row className="mt-3">
                    <Col><h2>2022</h2></Col>
                    <Col className="text-right"><button type="button" className="btn btn-success btn-lg">VIEW</button></Col>
                  </Row>

                </Link>

              </Col>
              <Col>
                <Link to="/parade/2022-2">

                  <img src={parade2022_2} className="img-fluid" alt="Awards"/>

                  <Row className="mt-3">
                    <Col><h2>2022</h2></Col>
                    <Col className="text-right"><button type="button" className="btn btn-success btn-lg">VIEW</button></Col>
                  </Row>


                </Link>
              </Col>
          </div>
          <div className="row mt-4 mb-5">
              <Col>
                <Link to="/parade/2019">

                  <img src={parade2019} className="img-fluid" alt="Awards"/>

                  <Row className="mt-3">
                    <Col><h2>2019</h2></Col>
                    <Col className="text-right"><button type="button" className="btn btn-success btn-lg">VIEW</button></Col>
                  </Row>


                </Link>

              </Col>
              <Col>
                <Link to="/parade/2018">

                  <img src={parade2018} className="img-fluid" alt="Awards"/>

                  <Row className="mt-3">
                    <Col><h2>2018</h2></Col>
                    <Col className="text-right"><button type="button" className="btn btn-success btn-lg">VIEW</button></Col>
                  </Row>

                </Link>
              </Col>
          </div>

        </div>

      </Layout>
    )
  }
};

export default AboutPage;
